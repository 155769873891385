import { getWindow } from '@surfline/web-common';

export const postMessageToNativeApp = (handlerName: string, data?: any): void => {
  const window = getWindow() as any;
  if (window?.webkit === undefined) {
    return;
  }
  const handler = window.webkit?.messageHandlers[handlerName];
  if (handler !== undefined) {
    handler.postMessage(data);
  }
};

export default postMessageToNativeApp;
