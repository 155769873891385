import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns-tz';
import { useSessionStorage } from 'react-use';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { trackEvent } from '@surfline/web-common';

import Thumb from 'components/Icons/Thumb';
import PadlockIcon from 'components/Icons/Padlock';
import Shaka from 'components/Icons/Shaka';
import config from 'config';
import { useUserPermissionStatus } from 'selectors/user';

import styles from './SubregionForecastFeedback.module.scss';

const upgradeUrl = `${config.surflineHost}${config.funnelUrl}`;

interface Props {
  contentBlock?: string;
  contentBody?: string;
  contentHeadline?: string;
  date: Date;
  forecasterName: string;
  spotId?: string;
  spotName?: string;
  subregionId: string;
  subregionName: string;
  timezone: string;
  type: 'today' | 'tomorrow' | 'highlights';
}

const SubregionForecastFeedback: React.FC<Props> = ({
  contentBlock,
  contentBody,
  contentHeadline,
  date,
  forecasterName,
  spotId,
  spotName,
  subregionId,
  subregionName,
  timezone,
  type,
}) => {
  const { hasSurfReportPermissions } = useUserPermissionStatus();

  const formattedDate = useMemo(
    () => format(date, 'MM-dd-yyyy', { timeZone: timezone }),
    [date, timezone],
  );

  const [feedback, setFeedback] = useSessionStorage<'yes' | 'no' | null>(
    `sl_subregionFeedback_${subregionId}_${formattedDate}${
      type === 'highlights' ? '_highlights' : ''
    }`,
    null,
  );

  const message = useMemo(() => {
    if (feedback === 'yes') return 'Stoked, hope you score!';
    if (feedback === 'no') return 'Thank you for the feedback.';
    return 'Was this forecast information useful?';
  }, [feedback]);

  const feedbackClicked = useCallback(
    (value: 'yes' | 'no') => {
      setFeedback(value);
      trackEvent('Content Feedback Submitted', {
        bodyContent: contentBody,
        contentBlock,
        forecasterName,
        headlineContent: contentHeadline,
        isUseful: value === 'yes',
        spotId,
        spotName,
        subregionId,
        subregionName,
      });
    },
    [
      contentBlock,
      contentBody,
      contentHeadline,
      forecasterName,
      setFeedback,
      spotId,
      spotName,
      subregionId,
      subregionName,
    ],
  );

  const onClickYesHandler = useCallback(() => {
    feedbackClicked('yes');
  }, [feedbackClicked]);

  const onClickNoHandler = useCallback(() => {
    feedbackClicked('no');
  }, [feedbackClicked]);

  const onClickUpgradeHandler = useCallback(() => {
    trackEvent('Clicked Subscribe CTA', {
      spotId,
      location: `forecast content graph`,
      spotName,
      pageName: 'Spot Report',
      contentBlock,
    });
  }, [contentBlock, spotId, spotName]);

  if (!hasSurfReportPermissions) {
    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <PadlockIcon className={styles.paywallLock} locked />
        <Typography className={styles.paywallText} variant="footnote" component="span">
          Read the rest with Premium
        </Typography>
        <Button
          disableFocusRipple
          disableRipple
          disableTouchRipple
          variant="primary"
          color="dark"
          size="small"
          href={upgradeUrl}
          onClick={onClickUpgradeHandler}
        >
          Upgrade
        </Button>
      </Stack>
    );
  }

  return (
    <div className={styles.wrapper} data-testid="subregion-forecast-feedback">
      <Typography
        className={styles.message}
        component="p"
        data-testid="feedback-message"
        mr={2}
        variant="footnote"
      >
        {message}
      </Typography>
      <div className={styles.actions}>
        {feedback !== 'yes' && feedback !== 'no' && (
          <>
            <IconButton
              className={styles.feedbackIcon}
              data-testid="feedback-button-yes"
              onClick={onClickYesHandler}
              size="medium"
            >
              <Thumb direction="up" />
            </IconButton>
            <IconButton
              className={styles.feedbackIcon}
              data-testid="feedback-button-no"
              onClick={onClickNoHandler}
              size="medium"
            >
              <Thumb direction="down" />
            </IconButton>
          </>
        )}
        {feedback === 'yes' && (
          <div className={classNames(styles.feedbackIcon, styles.feedbackPositive)}>
            <Shaka />
          </div>
        )}
        {feedback === 'no' && (
          <div className={classNames(styles.feedbackIcon, styles.feedbackNegative)}>
            <Shaka />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubregionForecastFeedback;
