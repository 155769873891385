import React from 'react';

const Share = () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 9.67018C0 9.164 0.391751 8.75366 0.875 8.75366C1.35825 8.75366 1.75 9.164 1.75 9.67018V13.1669H12.25V9.67018C12.25 9.164 12.6418 8.75366 13.125 8.75366C13.6082 8.75366 14 9.164 14 9.67018V13.1669C14 14.1793 13.2165 15 12.25 15H1.75C0.783502 15 0 14.1793 0 13.1669V9.67018Z"
      fill="white"
    />
    <path
      d="M3.67545 4.68715C3.3522 4.34893 3.3522 3.81626 3.67545 3.47804L6.36691 0.66189C6.71154 0.301299 7.28741 0.301299 7.63204 0.661889L10.3235 3.47803C10.6468 3.81626 10.6468 4.34893 10.3235 4.68715L10.2838 4.72869C9.93918 5.08928 9.36331 5.08928 9.01868 4.72869L7.8834 3.54081V9.73779C7.8834 10.221 7.49165 10.6128 7.0084 10.6128H6.99055C6.5073 10.6128 6.11555 10.221 6.11555 9.73779V3.54081L4.98027 4.72868C4.63564 5.08927 4.05977 5.08928 3.71514 4.72869L3.67545 4.68715Z"
      fill="white"
    />
  </svg>
);

export default Share;
