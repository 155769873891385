import { type ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { format, isToday, isTomorrow } from 'date-fns';
import { utcToZonedTime, format as formatTz, getTimezoneOffset } from 'date-fns-tz';
import { Card, CardActions, CardContent, CardHeader, IconButton } from '@mui/material';

import LastUpdate from 'components/LastUpdate';
import SubregionForecastFeedback from 'components/SubregionForecastFeedback';
import SubregionForecastLink from 'components/SubregionForecastLink';
import type { SubregionForecastCardType } from 'types/subregion';
import { oneHour, oneSecond } from 'utils/time';

import Share from 'components/Icons/Share';
import { useUserPermissionStatus } from 'selectors/user';
import { postMessageToNativeApp } from 'utils/postMessageToNativeApp';
import styles from './SubregionForecastCard.module.scss';

const getFormattedDate = (date: Date) => {
  const zonedDate = utcToZonedTime(date, 'UTC');
  if (isToday(zonedDate)) return 'Today';
  if (isTomorrow(zonedDate)) return 'Tomorrow';
  return format(zonedDate, 'EEEE');
};

const getFormattedStartDate = (date: Date) => {
  const zonedDate = utcToZonedTime(date, 'UTC');
  return `forecast-day-${format(zonedDate, 'yyyy-MM-dd')}`;
};

const getTimezoneAbbreviation = (date: Date, timezone: string) =>
  formatTz(utcToZonedTime(date, timezone), 'zzz', { timeZone: timezone });

interface SubregionForecastCardProps {
  className?: string;
  children: ReactNode;
  contentBlock?: string;
  contentBody?: string;
  contentHeadline?: string;
  date?: Date;
  forecasterName: string;
  isFeedbackActive?: boolean;
  lastUpdate?: Date;
  pageName?: string;
  showModalLink?: boolean;
  spotId?: string;
  spotName?: string;
  subregionId: string;
  subregionName: string;
  timezone?: string;
  title?: ReactNode;
  type: SubregionForecastCardType;
  showShareIcons?: boolean;
  isNative?: boolean;
}

const SubregionForecastCard: React.FC<SubregionForecastCardProps> = ({
  children,
  className,
  contentBlock,
  contentBody,
  contentHeadline,
  date,
  forecasterName,
  isFeedbackActive,
  lastUpdate,
  pageName,
  showModalLink = false,
  spotId,
  spotName,
  subregionId,
  subregionName,
  timezone,
  title,
  type,
  showShareIcons = false,
  isNative = false,
}) => {
  const formattedDate = useMemo(() => (date ? getFormattedDate(date) : null), [date]);

  const timezoneAbbr = useMemo(
    () => (date && timezone ? getTimezoneAbbreviation(date, timezone) : 'UTC'),
    [date, timezone],
  );

  const utcOffset = useMemo(
    () => (date && timezone ? getTimezoneOffset(timezone, date) / oneHour : 0),
    [date, timezone],
  );

  const timestamp = useMemo(() => (lastUpdate?.getTime() ?? 0) / oneSecond, [lastUpdate]);

  const startDate = useMemo(
    () => (date ? getFormattedStartDate(date) : 'forecast-highlights'),
    [date],
  );

  const { hasPremiumAnalysisPermissions } = useUserPermissionStatus();

  const onClickShareHandler = (forecastType: SubregionForecastCardType, forecastDate?: Date) => {
    let sharedContent: SubregionForecastCardType | string = forecastType;
    if (forecastType !== 'highlights' && forecastDate !== undefined) {
      sharedContent = format(forecastDate, 'yyyy-MM-dd');
    }
    postMessageToNativeApp('onForecastContentShare', {
      sharedContent,
    });
  };

  return (
    <Card
      className={classNames(styles.card, className)}
      component="section"
      data-testid="subregion-forecast-card"
      elevation={0}
      id={`forecast-${type}`}
    >
      <CardHeader
        component="header"
        title={title || formattedDate}
        subheader={
          lastUpdate ? (
            <LastUpdate
              abbrTimezone={timezoneAbbr}
              timestamp={timestamp}
              utcOffset={utcOffset}
              rawTimestampLabel
            />
          ) : null
        }
        action={
          <div className={styles.actions}>
            {showModalLink && (
              <SubregionForecastLink
                contentBlock={contentBlock}
                forecasterName={forecasterName}
                pageName={pageName}
                spotId={spotId}
                start={startDate}
                subregionId={subregionId}
                subregionName={subregionName}
              />
            )}
            {hasPremiumAnalysisPermissions && showShareIcons && isNative && (
              <IconButton
                className={styles.shareIcon}
                data-testid="forecast-content-share-button"
                onClick={() => onClickShareHandler(type, date)}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              >
                <Share />
              </IconButton>
            )}
          </div>
        }
      />
      <CardContent>{children}</CardContent>
      {isFeedbackActive && date && timezone && (
        <CardActions disableSpacing>
          <SubregionForecastFeedback
            contentBlock={contentBlock}
            contentBody={contentBody}
            contentHeadline={contentHeadline}
            date={date}
            forecasterName={forecasterName}
            spotId={spotId}
            spotName={spotName}
            subregionId={subregionId}
            subregionName={subregionName}
            timezone={timezone}
            type={type}
          />
        </CardActions>
      )}
    </Card>
  );
};

export default SubregionForecastCard;
