import { useCallback } from 'react';
import Link from 'next/link';
import { useContextualRouting } from 'next-use-contextual-routing';
import { IconButton } from '@mui/material';
import { trackEvent } from '@surfline/web-common';

import Expand from 'components/Icons/Expand';
import { subregionForecastPath } from 'utils/urls';

import styles from './SubregionForecastLink.module.scss';

interface Props {
  contentBlock?: string; // Today, Tomorrow, Forecast highlights, Day 0, Day 1, ...Day 2 - Day 15
  forecasterName?: string | null;
  pageName?: string; // Home, Spot Report, Premium Analysis, Charts
  spotId?: string;
  start?: string;
  subregionId: string;
  subregionName: string;
}

const SubregionForecastLink: React.FC<Props> = ({
  contentBlock,
  forecasterName = null,
  pageName,
  spotId,
  start,
  subregionId,
  subregionName,
}) => {
  const { makeContextualHref } = useContextualRouting();

  const contextualHref = makeContextualHref({
    subregionForecast: subregionId,
    start,
  });
  const contextualHrefAs = subregionForecastPath({ subregionId, subregionName, spotId });

  const onClickHandler = useCallback(() => {
    trackEvent('Content Expanded', {
      contentBlock,
      forecasterName,
      pageName,
      spotId,
      subregionId,
      subregionName,
    });
  }, [contentBlock, forecasterName, pageName, spotId, subregionId, subregionName]);

  return (
    <Link href={contextualHref} as={contextualHrefAs} shallow>
      <IconButton
        aria-label="Subregion Forecast"
        className={styles.expandButton}
        data-testid="subregion-forecast-link-button"
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={onClickHandler}
      >
        <Expand />
      </IconButton>
    </Link>
  );
};

export default SubregionForecastLink;
